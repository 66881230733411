

export const KnowledgeItemsConfig = {
    'PERSON': {
        trueLabel: 'Person',
        iconPath: null,
    },
    'ORG': {
        trueLabel: 'Organization',
        iconPath: null,
    },
    'GPE': {
        trueLabel: 'Geopolitical Entity',
        iconPath: null,
    },
    'LOC': {
        trueLabel: 'Location',
        iconPath: null,
    },
    'PRODUCT': {
        trueLabel: 'Product or Item',
        iconPath: null,
    },
    'EVENT': {
        trueLabel: 'Event',
        iconPath: null,
    },
    'WORK_OF_ART': {
        trueLabel: 'Art',
        iconPath: null,
    },
    'LAW': {
        trueLabel: 'Legal or Law',
        iconPath: null,
    },
    'LANGUAGE': {
        trueLabel: 'Language',
        iconPath: null,
    },
    'DATE': {
        trueLabel: 'Date',
        iconPath: null,
    },
    'TIME': {
        trueLabel: 'Time',
        iconPath: null,
    },
    'PERCENT': {
        trueLabel: 'Percentage',
        iconPath: null,
    },
    'MONEY': {
        trueLabel: 'Money',
        iconPath: null,
    },
    'QUANTITY': {
        trueLabel: 'Quantity',
        iconPath: null,
    },
    'ORDINAL': {
        trueLabel: 'Number',
        iconPath: null,
    },
    'CARDINAL': {
        trueLabel: 'Number',
        iconPath: null,
    },
    'FAC': {
        trueLabel: 'Facility',
        iconPath: null,
    },
    'NORP': {
        trueLabel: 'Nationality or Ethnicity',
        iconPath: null,
    },
    'WEAPON': {
        trueLabel: 'Weapon',
        iconPath: null,
    },
    'LICENSE': {
        trueLabel: 'License Plate',
        iconPath: null,
    },
}