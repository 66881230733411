import {HttpMethod} from "./httpMethods";


export const MasslawApiCalls = {
    GET_MY_STATUS:                      {rout: '/users/me/status', method: HttpMethod.GET,},
    SET_USER_DATA:                      {rout: '/users/me', method: HttpMethod.POST,},
    POST_PROFILE_PICTURE:               {rout: '/users/me/pfp', method: HttpMethod.POST,},
    GET_USER_DATA:                      {rout: '/users/{user_id}', method: HttpMethod.GET,},
    SEARCH_USERS:                       {rout: '/users/search', method: HttpMethod.GET,},

    GET_CASES:                          {rout: '/cases', method: HttpMethod.GET,},
    PUT_CASE:                           {rout: '/cases', method: HttpMethod.PUT,},
    POST_CASE:                          {rout: '/cases/{case_id}', method: HttpMethod.POST,},
    GET_CASE_DATA:                      {rout: '/cases/{case_id}', method: HttpMethod.GET,},
    GET_CASE_CONTENT_HIERARCHY:         {rout: '/cases/{case_id}/content_hierarchy', method: HttpMethod.GET,},
    SEARCH_CASE_FILES_TEXT:             {rout: '/cases/{case_id}/files/search', method: HttpMethod.GET,},
    START_CASE_FILE_UPLOAD:             {rout: '/cases/{case_id}/files/upload/start', method: HttpMethod.POST,},
    FINISH_CASE_FILE_UPLOAD:            {rout: '/cases/{case_id}/files/upload/finish', method: HttpMethod.POST,},
    GET_CASE_FILE_DATA:                 {rout: '/cases/{case_id}/files/{file_id}', method: HttpMethod.GET,},
    POST_CASE_FILE_DATA:                {rout: '/cases/{case_id}/files/{file_id}', method: HttpMethod.POST,},
    GET_CASE_FILE_CONTENT:              {rout: '/cases/{case_id}/files/{file_id}/content', method: HttpMethod.GET,},
    GET_FILE_COMMENTS:                  {rout: '/cases/{case_id}/files/{file_id}/comments', method: HttpMethod.GET,},
    PUT_COMMENT:                        {rout: '/cases/{case_id}/files/{file_id}/comments', method: HttpMethod.PUT,},
    DELETE_COMMENT:                     {rout: '/cases/{case_id}/files/{file_id}/comments/{comment_id}', method: HttpMethod.DELETE,},
    GET_COMMENT:                        {rout: '/cases/{case_id}/files/{file_id}/comments/{comment_id}', method: HttpMethod.GET,},
    POST_COMMENT:                       {rout: '/cases/{case_id}/files/{file_id}/comments/{comment_id}', method: HttpMethod.POST,},
    GET_COMMENT_CHILDREN:               {rout: '/cases/{case_id}/files/{file_id}/comments/{comment_id}/children', method: HttpMethod.POST,},
    GET_CASE_KNOWLEDGE:                 {rout: '/cases/{case_id}/knowledge', method: HttpMethod.GET,},
    GET_CASE_KNOWLEDGE_ITEM:            {rout: '/cases/{case_id}/knowledge/{item_type}/{item_id}', method: HttpMethod.GET,},
    POST_CASE_USER:                     {rout: '/cases/{case_id}/users/{user_id}', method: HttpMethod.POST,},
    GET_CASE_CONVERSATIONS:             {rout: '/cases/{case_id}/conversations', method: HttpMethod.GET,},
    PUT_CASE_CONVERSATION:              {rout: '/cases/{case_id}/conversations', method: HttpMethod.PUT,},
    GET_CASE_CONVERSATION_MESSAGES:     {rout: '/cases/{case_id}/conversations/{conversation_id}/messages', method: HttpMethod.GET,},
    POST_CASE_CONVERSATION_MESSAGE:     {rout: '/cases/{case_id}/conversations/{conversation_id}/messages', method: HttpMethod.POST,},
}
