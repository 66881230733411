export const SVG_PATHS = {
    homeFilament1: "M 1000 0 C 820 10 900 320 660 400 C 420 470 240 240 0 400 C 240 260 360 470 640 400 C 920 320 780 50 1000 0 C 800 30 880 320 680 400 C 480 470 240 250 0 400",

    genericFilament1: "M 0 0 C 116 233 366 166 516 283 C 683 400 666 483 950 550 C 1266 616 1166 866 1283 1000 C 1166 850 1266 600 950 566 C 716 533 700 383 533 283 C 366 166 100 233 0 0 C 133 233 366 166 500 283 C 666 416 616 433 950 533 C 1266 633 1166 883 1283 1000",

    logoTop: "M 0.173 0.1865 L 0.5 0.375 L 0.8265 0.1865 L 1 0.1865 L 0.5 0.475 L 0 0.1865 Z",
    logoBottom: "M 0.173 0.8135 L 0.5 0.625 L 0.8265 0.8135 L 1 0.8135 L 0.5 0.525 L 0 0.8135 Z ",
    logoRight: "M 1 0.2365 L 1 0.7635 L 0.975 0.749 L 0.975 0.2505 Z M 0.95 0.265 L 0.95 0.735 L 0.925 0.7205 L 0.925 0.2795 Z M 0.9 0.294 L 0.9 0.706 L 0.875 0.6915 L 0.875 0.3085 Z",
    logoLeft: "M 0 0.2365 L 0 0.7635 L 0.025 0.749 L 0.025 0.2505 Z M 0.05 0.265 L 0.05 0.735 L 0.075 0.7205 L 0.075 0.2795 Z M 0.1 0.294 L 0.1 0.706 L 0.125 0.6915 L 0.125 0.3085 Z ",
    logo: "M 0.173 0.186 L 0.5 0.375 L 0.826 0.186 L 1 0.186 L 0.5 0.475 L 0 0.186 Z M 0.173 0.813 L 0.5 0.625 L 0.826 0.813 L 1 0.813 L 0.5 0.525 L 0 0.813 Z M 1 0.236 L 1 0.763 L 0.975 0.749 L 0.975 0.25 Z M 0.95 0.265 L 0.95 0.734 L 0.925 0.72 L 0.925 0.279 Z M 0.9 0.294 L 0.9 0.706 L 0.875 0.691 L 0.875 0.308 Z M 0 0.236 L 0 0.763 L 0.025 0.749 L 0.025 0.25 Z M 0.05 0.265 L 0.05 0.734 L 0.075 0.72 L 0.075 0.279 Z M 0.1 0.294 L 0.1 0.706 L 0.125 0.691 L 0.125 0.308 Z",

    paperFlying1: "M 0.511 0 C 0.681 0.111 0.844 0.066 1 0.14 C 0.834 0.313 0.325 0.481 0.503 0.659 C 0.407 0.718 0.118 0.459 0 0.362 C 0.118 0.185 0.392 0.192 0.511 0 M 0.518 0.066 C 0.637 0.125 0.777 0.118 0.881 0.162 M 0.422 0.162 C 0.474 0.185 0.525 0.185 0.57 0.207 M 0.592 0.214 C 0.637 0.237 0.666 0.251 0.718 0.259 M 0.148 0.325 C 0.177 0.362 0.222 0.37 0.259 0.392 M 0.474 0.259 C 0.439 0.288 0.385 0.311 0.37 0.348 C 0.422 0.362 0.444 0.392 0.481 0.414 C 0.525 0.385 0.555 0.348 0.614 0.325 C 0.555 0.318 0.52 0.281 0.474 0.259",
    paperFlying2: "M 0.086 0 C 0.399 0 0.74 0.172 1 0.613 C 0.958 0.938 0.6 1.004 0.46 1.345 C 0.366 1.053 0.502 0.724 0 0.617 C -0.037 0.419 0.057 0.197 0.086 0 M 0.115 0.539 C 0.09 0.432 0.156 0.3 0.168 0.168 M 0.374 0.152 C 0.366 0.226 0.329 0.279 0.316 0.362 M 0.432 0.765 C 0.46 0.67 0.567 0.646 0.596 0.543 M 0.893 0.637 C 0.876 0.716 0.839 0.724 0.748 0.847",
    paperFlying3: "M 0 0.265 C 0 0.265 0 0.265 0 0.265 C 0.167 0.177 0.391 0.16 0.503 0 C 0.666 0.221 0.804 0.496 1 0.664 C 0.743 0.752 0.503 0.902 0.244 0.93 C 0.188 0.601 0.027 0.538 0 0.265 M 0.104 0.334 C 0.23 0.271 0.385 0.262 0.487 0.165 M 0.279 0.663 C 0.323 0.614 0.436 0.597 0.491 0.557 M 0.815 0.604 C 0.705 0.638 0.624 0.727 0.542 0.737 M 0.46 0.321 C 0.476 0.37 0.51 0.405 0.535 0.447 C 0.584 0.426 0.634 0.425 0.682 0.385 C 0.631 0.345 0.62 0.296 0.576 0.263 C 0.539 0.294 0.498 0.301 0.46 0.321",
    paperFlying4: "M 0.532 0 C 0.662 0.038 0.766 0.094 1 0.114 C 0.68 0.11 0.643 0.434 0.614 0.549 C 0.573 0.68 0.442 0.598 0.475 0.532 C 0.316 0.527 0.155 0.528 0 0.516 M 0.532 0.618 C 0.362 0.598 0.191 0.577 0.02 0.557 C 0 0.553 0.006 0.532 0 0.516 M 0.061 0.52 C 0.229 0.336 0.204 -0.049 0.532 0 M 0.479 0.04 C 0.583 0.054 0.639 0.09 0.717 0.098 M 0.586 0.348 C 0.495 0.352 0.409 0.323 0.327 0.319 M 0.163 0.475 C 0.241 0.471 0.274 0.487 0.356 0.487",
    paperFlying5: "M 0.429 0 C 0.511 0.188 0.853 0.266 1 0.087 C 0.904 0.32 0.654 0.508 0.429 0.55 C 0.261 0.696 0.025 0.505 0 0.362 C 0.241 0.308 0.376 0.154 0.429 0 M 0.438 0.098 C 0.485 0.157 0.547 0.188 0.62 0.207 M 0.685 0.221 C 0.769 0.238 0.851 0.23 0.929 0.179 M 0.494 0.25 C 0.558 0.297 0.679 0.325 0.792 0.308 M 0.165 0.398 C 0.193 0.469 0.278 0.528 0.376 0.516 M 0.483 0.356 C 0.536 0.384 0.584 0.398 0.651 0.396 C 0.598 0.432 0.544 0.46 0.485 0.48 C 0.429 0.477 0.376 0.455 0.345 0.429 C 0.391 0.405 0.446 0.396 0.483 0.356",
    paperFlying6: "M 0.154 0 C 0.037 0.303 -0.059 0.567 0.051 0.787 C 0.275 1.134 0.924 1.075 1 0.872 C 0.987 0.566 0.952 0.29 0.742 0.023 C 0.945 0.535 0.008 0.512 0.154 0 M 0.243 0.956 C 0.144 0.909 0.144 0.794 0.181 0.716 C 0.077 0.682 -0.01 0.602 0.007 0.491 M 0.121 0.208 C 0.101 0.317 0.056 0.422 0.062 0.533 M 0.26 0.87 C 0.344 0.796 0.304 0.65 0.325 0.539 M 0.809 0.216 C 0.87 0.335 0.9 0.457 0.916 0.556 M 0.921 0.622 C 0.927 0.695 0.945 0.763 0.939 0.834 M 0.55 0.433 C 0.57 0.512 0.574 0.603 0.57 0.675 M 0.567 0.739 C 0.567 0.786 0.57 0.835 0.567 0.879",

    plusSign: "M 0.46 0.1 A 0.0397 0.0397 90 0 0 0.42 0.14 L 0.42 0.3797 A 0.0397 0.0397 90 0 1 0.3797 0.42 L 0.14 0.42 A 0.0397 0.0397 90 0 0 0.1 0.46 L 0.1 0.54 A 0.0397 0.0397 90 0 0 0.14 0.58 L 0.3797 0.58 A 0.0397 0.0397 90 0 1 0.42 0.6197 L 0.42 0.86 A 0.0397 0.0397 90 0 0 0.46 0.9 L 0.54 0.9 A 0.0397 0.0397 90 0 0 0.58 0.86 L 0.58 0.6197 A 0.0397 0.0397 90 0 1 0.6197 0.58 L 0.86 0.58 A 0.0397 0.0397 90 0 0 0.9 0.54 L 0.9 0.46 A 0.0397 0.0397 90 0 0 0.86 0.42 L 0.6197 0.42 A 0.0397 0.0397 90 0 1 0.58 0.3797 L 0.58 0.14 A 0.0397 0.0397 90 0 0 0.54 0.1 Z",
    minusSign: "M 0.9 0.46 A 0.04 0.04 90 0 0 0.86 0.42 L 0.14 0.42 A 0.04 0.04 90 0 0 0.1 0.46 L 0.1 0.54 A 0.04 0.04 90 0 0 0.14 0.58 L 0.86 0.58 A 0.04 0.04 90 0 0 0.9 0.54 Z",
    checkMark: "M 0.5 0 A 0.02 0.02 90 0 0 0.5 1 A 0.02 0.02 90 0 0 0.5 0 M 0.74 0.22 L 0.86 0.34 L 0.42 0.78 L 0.16 0.5 L 0.28 0.38 L 0.42 0.54 L 0.74 0.22",
    crossMark: "M 0.5 0 A 0.02 0.02 90 0 0 0.5 1 A 0.02 0.02 90 0 0 0.5 0 M 0.72 0.16 L 0.84 0.28 L 0.62 0.5 L 0.84 0.72 L 0.72 0.84 L 0.5 0.62 L 0.28 0.84 L 0.16 0.72 L 0.38 0.5 L 0.16 0.28 L 0.28 0.16 L 0.5 0.38 L 0.72 0.16",

    meatballs: "M 0.5 0 A 0.125 0.125 90 0 0 0.5 0.25 A 0.125 0.125 90 0 0 0.5 0 M 0.5 0.375 A 0.125 0.125 90 0 0 0.5 0.625 A 0.125 0.125 90 0 0 0.5 0.375 M 0.5 0.75 A 0.125 0.125 90 0 0 0.5 1 A 0.125 0.125 90 0 0 0.5 0.75",

    circle: "M 0.5 0 A 0.02 0.02 90 0 0 0.5 1 A 0.02 0.02 90 0 0 0.5 0",

    person: "M 0.5 0 A 0.025 0.025 90 0 0 0.5 0.5 A 0.025 0.025 90 0 0 0.5 0 M 0.5 0.625 C 0.625 0.625 0.925 0.625 0.925 1 L 0.075 1 C 0.075 0.625 0.375 0.625 0.5 0.625",

    folder: "M 0 0.2 A 0.1 0.1 90 0 1 0.1 0.1 L 0.3 0.1 C 0.4 0.1 0.4 0.2 0.5 0.2 L 0.9 0.2 A 0.1 0.1 90 0 1 1 0.3 L 1 0.8 A 0.1 0.1 90 0 1 0.9 0.9 L 0.1 0.9 A 0.1 0.1 90 0 1 0 0.8 Z",
    file: "M 0.15 0.1 A 0.1 0.1 90 0 1 0.25 0 L 0.616 0 L 0.85 0.233 L 0.85 0.9 A 0.1 0.1 90 0 1 0.75 1 L 0.25 1 A 0.1 0.1 90 0 1 0.15 0.9 L 0.15 0.1 M 0.616 0 L 0.616 0.133 A 0.1 0.1 90 0 0 0.716 0.233 L 0.85 0.233 L 0.816 0.2 L 0.716 0.2 A 0.066 0.066 90 0 1 0.65 0.133 L 0.65 0.033 L 0.616 0 M 0.283 0.466 A 0.033 0.033 90 0 0 0.25 0.5 A 0.033 0.033 90 0 0 0.283 0.533 L 0.483 0.533 A 0.033 0.033 90 0 0 0.516 0.5 A 0.033 0.033 90 0 0 0.483 0.466 L 0.283 0.466 M 0.283 0.6 A 0.033 0.033 90 0 0 0.25 0.633 A 0.033 0.033 90 0 0 0.283 0.666 L 0.716 0.666 A 0.033 0.033 90 0 0 0.75 0.633 A 0.033 0.033 90 0 0 0.716 0.6 L 0.283 0.6 M 0.283 0.733 A 0.033 0.033 90 0 0 0.25 0.766 A 0.033 0.033 90 0 0 0.283 0.8 L 0.716 0.8 A 0.033 0.033 90 0 0 0.75 0.766 A 0.033 0.033 90 0 0 0.716 0.733 L 0.283 0.733",
    addFile: "M 0.362 0.325 A 0.075 0.075 90 0 1 0.437 0.25 L 0.712 0.25 L 0.887 0.425 L 0.887 0.925 A 0.075 0.075 90 0 1 0.812 1 L 0.437 1 A 0.075 0.075 90 0 1 0.362 0.925 L 0.362 0.325 M 0.712 0.25 L 0.712 0.35 A 0.075 0.075 90 0 0 0.787 0.425 L 0.887 0.425 L 0.862 0.4 L 0.787 0.4 A 0.05 0.05 90 0 1 0.737 0.35 L 0.737 0.275 L 0.712 0.25 M 0.462 0.6 A 0.025 0.025 90 0 0 0.437 0.625 A 0.025 0.025 90 0 0 0.462 0.65 L 0.612 0.65 A 0.025 0.025 90 0 0 0.637 0.625 A 0.025 0.025 90 0 0 0.612 0.6 L 0.462 0.6 M 0.462 0.7 A 0.025 0.025 90 0 0 0.437 0.725 A 0.025 0.025 90 0 0 0.462 0.75 L 0.787 0.75 A 0.025 0.025 90 0 0 0.812 0.725 A 0.025 0.025 90 0 0 0.787 0.7 L 0.462 0.7 M 0.462 0.8 A 0.025 0.025 90 0 0 0.437 0.825 A 0.025 0.025 90 0 0 0.462 0.85 L 0.787 0.85 A 0.025 0.025 90 0 0 0.812 0.825 A 0.025 0.025 90 0 0 0.787 0.8 L 0.462 0.8 M 0.3 0.312 L 0.3 0.187 L 0.425 0.187 L 0.425 0.125 L 0.3 0.125 L 0.3 0 L 0.237 0 L 0.237 0.125 L 0.112 0.125 L 0.112 0.187 L 0.237 0.187 L 0.237 0.312 Z",
    pen: "M 0.8 0.085 L 0.857 0.028 A 0.028 0.028 90 0 1 0.971 0.142 L 0.914 0.2 Z M 0.885 0.228 L 0.771 0.114 L 0.714 0.171 L 0.828 0.285 Z M 0.8 0.314 L 0.685 0.2 L 0.114 0.771 L 0.228 0.885 L 0.8 0.314 M 0.2 0.914 L 0.085 0.8 L 0.028 0.942 A 0.007 0.007 90 0 0 0.057 0.971 L 0.2 0.914 L 0.057 0.971",
    copy: "M 0.2 0.2 A 0.2 0.2 90 0 0 0 0.4 L 0 0.8 A 0.2 0.2 90 0 0 0.2 1 L 0.6 1 A 0.2 0.2 90 0 0 0.8 0.8 L 0.8 0.4 A 0.2 0.2 90 0 0 0.6 0.2 Z A 0.2 0.2 90 0 1 0.4 0 L 0.8 0 A 0.2 0.2 90 0 1 1 0.2 L 1 0.6 A 0.2 0.2 90 0 1 0.8 0.8 L 0.8 0.76 A 0.16 0.16 90 0 0 0.96 0.6 L 0.96 0.2 A 0.16 0.16 90 0 0 0.8 0.04 L 0.4 0.04 A 0.16 0.16 90 0 0 0.24 0.2 Z",
    addComment: "M 0.153 0.384 A 0.153 0.153 90 0 0 0.153 0.384 L 0.153 0.692 A 0.153 0.153 90 0 0 0.307 0.846 L 0.846 0.846 L 1 1 L 1 0.384 A 0.153 0.153 90 0 0 0.846 0.23 L 0.307 0.23 A 0.153 0.153 90 0 0 0.153 0.384 Z M 0.153 0.23 L 0.076 0.23 L 0.076 0.153 L 0 0.153 L 0 0.076 L 0.076 0.076 L 0.076 0 L 0.153 0 L 0.153 0.076 L 0.23 0.076 L 0.23 0.153 L 0.153 0.153 L 0.153 0.2308",
    send: "M 0.071 0 L 0.785 0.357 C 0.928 0.428 0.928 0.571 0.785 0.642 L 0.071 1 L 0.142 0.785 C 0.214 0.571 0.214 0.428 0.142 0.214 L 0.071 0",

    dashboard: "M 0.892 0.964 A 0.035 0.035 90 0 0 0.892 0.892 L 0.678 0.892 L 0.607 0.75 L 0.892 0.75 A 0.107 0.107 90 0 0 1 0.642 L 1 0.142 A 0.107 0.107 90 0 0 0.892 0.035 L 0.107 0.035 A 0.107 0.107 90 0 0 0 0.142 L 0 0.642 A 0.107 0.107 90 0 0 0.107 0.75 L 0.392 0.75 L 0.321 0.892 L 0.107 0.892 A 0.035 0.035 90 0 0 0.107 0.964 Z M 0.535 0.75 L 0.607 0.892 L 0.392 0.892 L 0.464 0.75 Z M 0.892 0.642 L 0.107 0.642 L 0.107 0.142 L 0.892 0.142 Z M 0.357 0.214 A 0.178 0.178 90 1 0 0.535 0.392 L 0.357 0.392 Z M 0.571 0.357 A 0.178 0.178 90 0 0 0.392 0.178 L 0.392 0.357 Z M 0.607 0.5 A 0.035 0.035 90 0 0 0.607 0.571 L 0.785 0.571 A 0.035 0.035 90 0 0 0.785 0.5 L 0.607 0.5 M 0.75 0.392 A 0.035 0.035 90 0 0 0.821 0.392 L 0.821 0.25 A 0.035 0.035 90 0 0 0.75 0.25 Z",
    search: "M 0.384 0 A 0.076 0.076 90 0 0 0.384 0.769 A 0.076 0.076 90 0 0 0.384 0 L 0.384 0.076 A 0.076 0.076 90 0 1 0.384 0.692 A 0.076 0.076 90 0 1 0.384 0.076 M 0.615 0.692 L 0.923 1 L 1 0.923 L 0.692 0.6154",
    knowledge: "M 0.472 0.222 A 0.138 0.138 90 1 0 0.444 0.25 L 0.583 0.388 A 0.138 0.138 90 0 0 0.583 0.555 L 0.388 0.75 A 0.138 0.138 90 1 0 0.416 0.777 L 0.611 0.583 A 0.138 0.138 90 1 0 0.611 0.361 L 0.472 0.222 M 0.416 0.194 A 0.027 0.027 90 0 1 0.305 0.083 A 0.027 0.027 90 0 1 0.416 0.194 M 0.638 0.416 A 0.027 0.027 90 0 1 0.75 0.527 A 0.027 0.027 90 0 1 0.638 0.416 M 0.361 0.805 A 0.027 0.027 90 0 1 0.25 0.916 A 0.027 0.027 90 0 1 0.361 0.8056",
    subjects: "M 0.69 0.272 A 0.018 0.018 90 0 0 0.69 0.636 A 0.018 0.018 90 0 0 0.69 0.272 M 0.69 0.727 C 0.781 0.727 1 0.727 1 1 L 0.381 1 C 0.381 0.727 0.6 0.727 0.69 0.727 M 0.09 0.09 A 0.018 0.018 90 0 0 0.09 0.181 L 0.909 0.181 A 0.018 0.018 90 0 0 0.909 0.09 Z M 0.09 0.454 A 0.018 0.018 90 0 0 0.09 0.545 L 0.496 0.545 A 0.181 0.181 90 0 1 0.474 0.454 Z M 0.907 0.454 A 0.181 0.181 90 0 1 0.885 0.545 L 0.909 0.545 A 0.018 0.018 90 0 0 0.909 0.454 Z Z M 0.09 0.818 A 0.018 0.018 90 0 0 0.09 0.909 L 0.363 0.909 C 0.363 0.89 0.381 0.836 0.4 0.818 Z",
    timeline: "M 0.1 0.95 A 0.025 0.025 90 0 0 0 0.95 A 0.025 0.025 90 0 0 0.1 0.95 M 0.15 0.925 A 0.025 0.025 90 0 0 0.15 0.975 L 0.85 0.975 A 0.025 0.025 90 0 0 0.85 0.925 Z M 0.875 0.525 A 0.025 0.025 90 0 0 0.875 0.475 L 0.525 0.475 A 0.025 0.025 90 0 0 0.525 0.525 L 0.875 0.525 M 0.45 0.7 A 0.025 0.025 90 0 0 0.45 0.65 L 0.2 0.65 A 0.025 0.025 90 0 0 0.2 0.7 L 0.45 0.7 Z M 0.312 0.9 A 0.002 0.002 90 0 0 0.337 0.9 L 0.362 0.762 A 0.002 0.002 90 0 0 0.287 0.762 Z M 0.687 0.9 A 0.002 0.002 90 0 0 0.712 0.9 L 0.737 0.6 A 0.002 0.002 90 0 0 0.662 0.6 Z M 0.25 0 A 0.025 0.025 90 0 0 0.25 0.5 A 0.025 0.025 90 0 0 0.25 0 L 0.25 0.05 A 0.025 0.025 90 0 1 0.25 0.45 A 0.025 0.025 90 0 1 0.25 0.05 M 0.275 0.225 L 0.275 0.1 A 0.025 0.025 90 0 0 0.225 0.1 L 0.225 0.225 A 0.025 0.025 90 0 0 0.275 0.275 L 0.35 0.275 A 0.025 0.025 90 0 0 0.35 0.225 L 0.275 0.225 M 0.9 0.95 A 0.025 0.025 90 0 0 1 0.95 A 0.025 0.025 90 0 0 0.9 0.95",
    conversations: "M 0.054 0.283 A 0.081 0.081 90 1 0 0.108 0.283 L 0.108 0.202 A 0.027 0.027 90 0 1 0.135 0.175 L 0.527 0.175 A 0.081 0.081 90 1 0 0.527 0.121 L 0.135 0.121 A 0.081 0.081 90 0 0 0.054 0.202 Z Z M 0.851 0.121 A 0.081 0.081 90 1 0 0.851 0.175 L 0.918 0.175 A 0.027 0.027 90 0 1 0.945 0.202 L 0.945 0.662 A 0.027 0.027 90 0 1 0.918 0.689 L 0.581 0.689 A 0.081 0.081 90 1 0 0.581 0.743 L 0.918 0.743 A 0.081 0.081 90 0 0 1 0.662 L 1 0.202 A 0.081 0.081 90 0 0 0.918 0.121 Z M 0.256 0.689 A 0.081 0.081 90 1 1 0.256 0.743 C 0.216 0.743 0.216 0.743 0.189 0.77 L 0.108 0.851 C 0.081 0.878 0.054 0.878 0.054 0.851 L 0.054 0.608 A 0.081 0.081 90 1 1 0.108 0.608 L 0.108 0.783 L 0.175 0.716 C 0.202 0.689 0.202 0.689 0.256 0.689 M 0.27 0.243 A 0.013 0.013 90 0 0 0.27 0.31 L 0.486 0.31 A 0.013 0.013 90 0 0 0.486 0.243 Z M 0.27 0.378 A 0.013 0.013 90 0 0 0.27 0.445 L 0.783 0.445 A 0.013 0.013 90 0 0 0.783 0.378 Z M 0.27 0.513 A 0.013 0.013 90 0 0 0.27 0.581 L 0.783 0.581 A 0.013 0.013 90 0 0 0.783 0.513 Z",

    gear: "M 1 0.567 V 0.432 C 0.931 0.408 0.887 0.401 0.865 0.348 V 0.348 C 0.843 0.295 0.87 0.259 0.901 0.194 L 0.805 0.098 C 0.74 0.129 0.704 0.156 0.651 0.134 H 0.651 C 0.598 0.112 0.591 0.068 0.567 0 H 0.432 C 0.408 0.068 0.401 0.112 0.348 0.134 H 0.348 C 0.295 0.156 0.259 0.13 0.194 0.098 L 0.098 0.194 C 0.129 0.259 0.156 0.295 0.134 0.348 C 0.112 0.401 0.068 0.408 0 0.432 V 0.567 C 0.068 0.591 0.112 0.598 0.134 0.651 C 0.156 0.704 0.129 0.741 0.098 0.805 L 0.194 0.901 C 0.259 0.87 0.295 0.843 0.348 0.865 H 0.348 C 0.401 0.887 0.408 0.931 0.432 1 H 0.567 C 0.591 0.931 0.598 0.887 0.651 0.865 H 0.651 C 0.704 0.843 0.74 0.869 0.805 0.901 L 0.901 0.805 C 0.87 0.74 0.843 0.704 0.865 0.651 C 0.887 0.598 0.931 0.591 1 0.567 Z M 0.5 0.666 C 0.407 0.666 0.333 0.592 0.333 0.5 S 0.407 0.333 0.5 0.333 S 0.666 0.407 0.666 0.5 S 0.592 0.666 0.5 0.666 Z",

    arrowRight: "M  0.1875 0.125 L 0.312 0 L 0.812 500 L 0.312 1000 L 0.187 0.875 L 0.562 0.500 L 0.187 0.125",
    arrowDown: "M 0.875 0.1875 L 1 0.3125 L 0.5 0.8125 L 0 0.3125 L 0.125 0.1875 L 0.5 0.5625 L 0.875 0.1875",

    circleArrow: "M 0.5 0.125 A 0.375 0.375 90 1 1 0.125 0.5 L 0 0.5 L 0.15 0.25 A 0.0288 0.0288 90 0 1 0.2 0.25 L 0.35 0.5 L 0.225 0.5 A 0.275 0.275 90 1 0 0.5 0.225 A 0.025 0.025 90 0 1 0.5 0.125",

    redirect: "M 0.4 0.4 L 0.7 0.1 L 0.6 0 L 1 0 L 1 0.4 L 0.9 0.3 L 0.6 0.6 A 0.1 0.1 90 0 1 0.4 0.4 M 0.4 0 L 0.5 0.1 L 0.4 0.2 L 0.3 0.2 A 0.1 0.1 90 0 0 0.2 0.3 L 0.2 0.7 A 0.1 0.1 90 0 0 0.3 0.8 L 0.7 0.8 A 0.1 0.1 90 0 0 0.8 0.7 L 0.8 0.6 L 0.9 0.5 L 1 0.6 L 1 0.7 A 0.3 0.3 90 0 1 0.7 1 L 0.3 1 A 0.3 0.3 90 0 1 0 0.7 L 0 0.3 A 0.3 0.3 90 0 1 0.3 0 Z",
}

